import axios from 'axios';
import authHeader from './auth-header';
import {BASIC_URL} from './global';

const API_URL = BASIC_URL+ '/api/ticket/';
const IMAGE_URL = BASIC_URL+ '/images/';


class TicketService {

    getAllTickets() {
        return axios.get(API_URL + 'all', {headers: authHeader()});
    }

    getImage(imageName) {
        return axios.get(IMAGE_URL + `image/${imageName}`);
    }

    getTicket(id) {
        return axios.get(API_URL + `get/${id}`, {headers: authHeader()});
    }

    deleteTicket(id){
        return axios.delete(API_URL + `delete/${id}`, {headers: authHeader()});
    }

    getAllUserTickets(username) {
        return axios.get(API_URL + `user/${username}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(API_URL + 'create', data, {headers: authHeader()});
    }

    comment(data) {
        return axios.post(API_URL + 'comment', data, {headers: authHeader()});
    }

    uploadImages(file) {
        let formData = new FormData();
        formData.append("file", file);
        return axios.post(API_URL + 'upload', formData,
            {
                headers: authHeader(),
                "Content-type": "application/json"
            });
    }

    getAllImages() {
        return axios.get(API_URL + 'images', {headers: authHeader()});
    }

    getBannerTickets(id) {
        return axios.get(API_URL + `banner-tickets/${id}`, {headers: authHeader()});
    }

    search(key) {
        return axios.get(API_URL + `all/?key=${key}`, {headers: authHeader()});
    }

}

export default new TicketService();
