<template>

  <div class="container">

    <Card style="margin-top: 20px">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Impressum</div>
      </template>


      <template #content>
      <div class="text-center">
        <p><span style="font-size: 16px;"><strong>Verein der Mongolischen Akademiker e.V.</strong></span></p>
        <p><span style="font-size: 14px;">Pfaffenwaldring 60,&nbsp;</span><span style="font-size: 14px;">70569 Stuttgart, Deutschland</span>
        </p>
        <p><span style="font-size: 14px;">+49 176 24099234</span></p>
        <p><span style="font-size: 14px;">info@vma-ev.com&nbsp;</span></p>
        <p>&nbsp;</p>
        <p><strong><span style="font-size: 14px;">Vertreten durch</span></strong></p>
        <p><span style="font-size: 14px;">Vorsitzender Tuvshintulga Sanjragchaa</span></p>
        <p><span style="font-size: 14px;">+49 176 81096251</span></p>
        <p><span style="font-size: 14px;">Stellv. Vorsitzende&nbsp;</span><span style="font-size: 14px;">Dr. Dolgor Guntsetseg</span>
        </p>
        <p><span style="font-size: 14px;">+49 176 24099234</span></p>
        <p>&nbsp;</p>
        <p><span style="font-size: 14px;"><strong>Vereinsregister, Vereinsregisternummer</strong></span></p>
        <p><span style="font-size: 14px;">Amtsgericht Stuttgart - Registergericht , VR 720 699</span></p>
        <p>&nbsp;</p>
      </div>
      </template>
    </Card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      impressum: "",
    };
  },
  mounted() {

  },
};
</script>
<style>


</style>
