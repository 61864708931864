<template>
  <Toast/>

  <div class="container">
    <div class="card margin-top-10 p-3" v-if="currentUser && userinfo">
      <div class="grid pr-3">
        <div class="col-12 md:col-6 lg:col-4">

          <div class="col-12 md:col-12 lg:col-12">
            <div class="flex align-items-center justify-content-center">
              <Avatar :image="userinfo.imageFileInfo.url" shape="circle" size="xlarge"
                      class="d-avatar"/>
            </div>
            <div class="float-right" style="margin-bottom: 10px;  margin-right: 20px">
              <Button icon="pi pi-pencil" class="p-button-raised p-button-rounded" @click="openAvatarDialog"/>
            </div>
            <Divider/>
            <div class="field grid">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Benutzername</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ currentUser.username }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.firstname && userinfo.lastname"/>
            <div class="field grid" v-if="userinfo.firstname && userinfo.lastname">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Name</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.firstname }} {{ userinfo.lastname }}
                </div>
              </div>
            </div>
            <Divider v-if="currentUser.email"/>
            <div class="field grid" v-if="currentUser.email">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">E-Mail</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ currentUser.email }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.yearOfBirth"/>
            <div class="field grid" v-if="userinfo.yearOfBirth">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Geburtsjahr</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.yearOfBirth }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.profession"/>
            <div class="field grid" v-if="userinfo.profession">
              <label for="profession" class="col-fixed font-bold" style="width:140px">Klasse</label>
              <div class="col">
                <div id="profession"
                     class="">
                  {{ userinfo.profession }}
                </div>
              </div>
            </div>


            <!--


                        <Divider v-if="userinfo.education"/>
                        <div class="field grid" v-if="userinfo.education">
                          <label for="education" class="col-fixed font-bold" style="width:140px">Beruf</label>
                          <div class="col">
                            <div id="education"
                                 class="">
                              {{ userinfo.education }}
                            </div>
                          </div>
                        </div>



                        <Divider v-if="userinfo.experienceYear"/>
                        <div class="field grid" v-if="userinfo.experienceYear">
                          <label for="experienceYear" class="col-fixed font-bold" style="width:140px">Erfahrungsjahr</label>
                          <div class="col">
                            <div id="experienceYear"
                                 class="">
                              {{ userinfo.experienceYear }}
                            </div>
                          </div>
                        </div>

                        <Divider v-if="userinfo.address"/>
                        <div class="field grid" v-if="userinfo.address">
                          <label for="location" class="col-fixed font-bold" style="width:140px">Ort</label>
                          <div class="col">
                            <div id="location"
                                 class="">
                              {{ userinfo.address }}
                            </div>
                          </div>
                        </div>

                        <Divider v-if="userinfo.phoneNumber"/>
                        <div class="field grid" v-if="userinfo.phoneNumber">
                          <label for="location" class="col-fixed font-bold" style="width:140px">Telefonnummer</label>
                          <div class="col">
                            <div id="location"
                                 class="">
                              {{ userinfo.phoneNumber }}
                            </div>
                          </div>
                        </div>


            <div class="float-right" style="margin-bottom: 10px; margin-right: 20px">
              <Button icon="pi pi-pencil" class="p-button-raised p-button-rounded" @click="openMainDialog"/>
            </div>
               -->
          </div>


        </div>

        <div class="col-12 md:col-6 lg:col-8">
          <Panel header="Über mich">
            <p v-if="userinfo.aboutUser">{{ userinfo.aboutUser }}</p>
          </Panel>

          <Panel header="Fähigkeiten und Kenntnisse" class="margin-top-10">
            <div v-if="userinfo.skills">
              <Tag severity="success" v-for="(next, index) in userinfo.skills" :key="index" :value="next"
                   class="mr-2 text-base" rounded/>
            </div>
          </Panel>


          <!--
          <Panel header="Erfahrung" class="margin-top-10 erfahrungView">

            <Editor v-if="userinfo.experience" v-model="userinfo.experience" readonly="true"
                    editorStyle="min-height: 190px; border:none">
              <template v-slot:toolbar>
                <span class="ql-formats">
                </span>
              </template>
            </Editor>

          </Panel>
          -->
          <div class="float-right" style="margin-top: 10px; margin-right:20px">
            <Button icon="pi pi-pencil" class="p-button-raised p-button-rounded" @click="openMainDialog"/>
          </div>

        </div>

      </div>
    </div>
    <TicketList :username="currentUser.username" v-if="currentUser && userinfo" ticketHeader="Meine Fragen"/>

  </div>
  <Dialog header="Benutzerdaten" v-model:visible="editMainDialog"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '50vw !important'}">
    <div class="p-fluid grid">
      <div class="field col-12 md:col-6">
        <InputText id="fname" v-model="userinfo.firstname" placeholder="Vorname" class=""/>
      </div>
      <div class="field col-12 md:col-6">
        <InputText id="name" v-model="userinfo.lastname" placeholder="Nachname" class=""/>
      </div>

      <div class="field col-12 md:col-6">
        <InputMask v-model="userinfo.yearOfBirth" mask="9999" placeholder="Geburtsjahr"/>
      </div>

      <div class="field col-12 md:col-6">
        <Dropdown v-model="userinfo.profession"
                  :options="professions"
                  optionLabel="name" optionValue="code" placeholder="Klasse wählen"/>

      </div>

      <div class="field col-12 md:col-12">
        <Chips v-model="userinfo.skills" placeholder="Kenntnisse" :separator="separatorExp"/>
      </div>


      <!--


      <div class="field col-12 md:col-6">
        <InputText id="address" v-model="userinfo.address" placeholder="Ort" class=""/>
      </div>

            <div class="field col-12 md:col-6">
              <InputText id="education" v-model="userinfo.education" placeholder="Beruf" class=""/>
            </div>

            <div class="field col-12 md:col-6">
              <InputText id="experienceYear" v-model="userinfo.experienceYear" placeholder="Erfahrungsjahre" class=""/>
            </div>

            <div class="field col-12 md:col-6">
              <InputText id="phonenumber" v-model="userinfo.phoneNumber" placeholder="Telefonnummer" class=""/>
            </div>


      -->

      <div class="field col-12 md:col-12">
        <Textarea id="name" v-model="userinfo.aboutUser" placeholder="Über mich" class="" rows="4"/>
      </div>


    </div>

    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" @click="resetData" class="p-button-text"/>
      <Button label="Speichern" icon="pi pi-check" @click="saveMain()" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Avatar" v-model:visible="editAvatarDialog"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '50vw !important'}">

    <FileUpload name="demo[]" style=""
                chooseLabel="Bild auswählen" upload-label="Hochladen" cancel-label="Abbrechen"
                :customUpload="true" multiple="false"

                @uploader="uploadAvatar"/>

  </Dialog>
  <Dialog header="Erfahrungen und Kenntnisse" v-model:visible="editExperienceDialog"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '50vw !important'}">
    <div class="p-fluid grid">



      <div class="field col-12 md:col-12">
        <Editor v-model="userinfo.experience" editorStyle="min-height: 190px;">
          <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
            </span>

            <span class="ql-formats">
                    <button class="ql-list" value="ordered" type="button"></button>
                    <button class="ql-list" value="bullet" type="button"></button>
                    <select class="ql-align">
                        <option defaultValue></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                </span>
            <span class="ql-formats">
                    <button class="ql-clean" type="button"></button>
                </span>
          </template>


        </Editor>
      </div>

    </div>

    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" @click="resetData" class="p-button-text"/>
      <Button label="Speichern" icon="pi pi-check" @click="saveMain" autofocus/>
    </template>
  </Dialog>

</template>

<script>

import UserService from "@/services/user.service";
import TicketList from "@/components/TicketList";
import dayjs from 'dayjs';


export default {
  name: 'Profile',
  components: {TicketList},

  data() {
    return {
      userinfo: null,
      editMainDialog: false,
      editExperienceDialog: false,
      editAvatarDialog: false,
      content: "",
      professions: [],
      separatorExp: /,| /
    };
  },


  methods: {

    uploadAvatar(event) {
      UserService.uploadImages(event.files[0])
          .then(response => {
            this.userinfo = response.data;
            this.closeAvatarDialog();
            this.$toast.add({severity: 'info', summary: "Avatar erfolgreich hochgeladen"});
          })
          .catch(() => {
            this.closeAvatarDialog();
            this.$toast.add({severity: 'info', summary: "Netzwerk Error"});
          });
    },

    getProfessions() {
      UserService.getUserProfessions()
          .then(response => {
            this.professions = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    saveMain() {
      UserService.updateUserInfo(this.userinfo)
          .then(response => {
            this.userinfo = response.data;
            console.log(response.data);
            this.closeMainDialog();
            this.closeExperienceDialog();
          })
          .catch(e => {
            console.log(e);
            this.$toast.add({severity: 'info', summary: "Netzwerk Error"});
            this.closeMainDialog();
            this.closeExperienceDialog();
          });
    },

    resetData() {
      this.getUserInfo(this.$store.state.auth.user.id);
      this.closeMainDialog();
      this.closeExperienceDialog();
    },


    openMainDialog() {
      this.editMainDialog = true;
    },
    closeMainDialog() {
      this.editMainDialog = false;
    },
    openExperienceDialog() {
      this.editExperienceDialog = true;
    },
    openAvatarDialog() {
      this.editAvatarDialog = true;
    },
    closeExperienceDialog() {
      this.editExperienceDialog = false;
    },
    closeAvatarDialog() {
      this.editAvatarDialog = false;
    },
    formatDateNumber(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format('DD.MM.YYYY');
    },

    getUserInfo(id) {

      UserService.getUserInfo(id)
          .then(response => {
                this.userinfo = response.data;
                console.log(response.data);
              }, (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.userinfo = null;
              }
          );
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },


  mounted() {
    this.getUserInfo(this.$store.state.auth.user.id);
    this.getProfessions();
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
}

</script>