import {createWebHistory, createRouter} from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";

// lazy-loaded
import Profile from "./components/MyProfile.vue";
import BoardAdmin from "./components/BoardAdmin.vue";
import BoardModerator from "./components/BoardModerator.vue";
import BoardUser from "./components/BoardUser.vue";
import BoardTickets from "./components/Tickets.vue";
import BoardTicketView from "@/components/TicketView.vue";
import BoardAddTicket from "@/components/AddTicket.vue";
import BoardBooks from "@/components/Books.vue";
import BoardTest from "@/components/Test.vue";
import MemberList from "@/components/MemberList";
import UserProfile from "@/components/UserProfile";
import BookView from "@/components/BookView";
import BoardLessons from "@/components/Lessons";
import Homework from "@/components/Homework";
import Impressum from "@/components/Impressum";
import Datenschutz from "@/components/Datenschutz";
import Project from "@/components/Project";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/impressum",
        component: Impressum,
    },
    {
        path: "/datenschutz",
        component: Datenschutz,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/my-profile",
        name: "my-profile",
        component: Profile,
    },
    {
        path: "/admin",
        name: "admin",
        component: BoardAdmin,
    },
    {
        path: "/project",
        name: "project",
        component: Project,
    },
    {
        path: "/mod",
        name: "moderator",
        component: BoardModerator,
    },
    {
        path: "/user",
        name: "user",
        // lazy-loaded
        component: BoardUser,
    },
    {
        path: "/tickets",
        name: "tickets",
        // lazy-loaded
        component: BoardTickets,
    },
    {
        path: "/books",
        name: "books",
        // lazy-loaded
        component: BoardBooks,
    },
    {
        path: "/members",
        name: "members",
        component: MemberList,
    },
    {
        path: "/createTickets",
        name: "createTickets",
        component: BoardAddTicket,
    },
    {
        path: "/lessons",
        name: "lesson",
        component: BoardLessons,
    },
    {
        path: '/ticket-view/:id',
        name: "ticket-view",
        component: BoardTicketView,
    },
    {
        path: '/test/:category',
        name: "test",
        component: BoardTest,
    },
    {
        path: '/homework',
        name: "homework",
        component: Homework,
    },
    {
        path: '/book-view',
        name: "book-view",
        component: BookView,
    },
    {
        path: '/user-profile/:id',
        name: "user-profile",
        // lazy-loaded
        component: UserProfile,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({behavior: 'smooth'});
    }
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;