<template>
<div class="container">
  <TicketSearchList  ticketHeader="Förderfragen"/>
</div>
  <!--
  <header v-if="currentUser" class="jumbotron margin-top-10">
    <h3>Hier findest du alle Förderfragen</h3>

    <button type="button" class="btn btn-outline-info" style="margin-top: 20px" @click="$router.push('createTickets')">
      Neue Frage erstellen
    </button>
  </header>
  {{ content }}


  <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-4" style="margin-bottom: 20px" v-for="(ticket, index) in tickets" :key="index">

      <div class="card" style="padding: 10px; ">
        <div class="product-image">

          <Image :src="ticket.imageFileInfo.url" alt="Image Text"
                 imageClass="rounded ticketImg" preview>
          </Image>

        </div>
        <div class="product-content" style="margin-top: 10px">
          <strong>Datum: am {{ formatDate(ticket.createdOn) }}</strong>
          <p class="title" style="margin-top: 10px; min-height: 100px"><strong>Frage: </strong><br>
            {{ ticket.title }}</p>

          <router-link class="btn btn-outline-info ticketDetailButton" type="button"
                       style="float: right"
                       :to="{name: 'ticket-view', params:{id: ticket.id}}">
            Frage öffnen
          </router-link>
        </div>
      </div>
    </div>
  </div>
-->

</template>


<script>
/*import UserService from "../services/user.service";*/
import TicketService from "../services/ticket-services";
import TicketServices from "@/services/ticket-services";
import TicketSearchList from "@/components/TicketSearchList";

export default {
  name: "User",
  components: {TicketSearchList},

  data() {

    return {
      content: "",
      tickets: [],
      currentIndex: -1,
      layout: 'grid',
      userfilter:'@all'
    };
  },


  methods: {
    retrieveTutorials() {
      TicketService.getAllTickets()
          .then(response => {
            this.tickets = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    getImage(imageName) {

      TicketServices.getImage(imageName)
          .then(response => {
            console.log(response.data);
            return response.data;
          })
          .catch(e => {
            console.log(e);
            return null;
          });
    },


    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);

    },
  },


  mounted() {
    TicketService.getAllTickets().then(
        (response) => {
          this.tickets = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  }
};
</script>

<style scoped>

</style>
