<template>
  <div class="container">

    <Card style="margin-top: 20px" v-if="books">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Bücher und die Schulmaterialien</div>
      </template>
      <template #content>
        <DataTable :value="books" responsiveLayout="scroll" :paginator="true" :rows="10">
          <Column>
            <template #body="slotProps">
              <router-link :to="{name: 'book-view', query:{bookId: slotProps.data.id}}">

                <Image :src="slotProps.data.imageFileInfo.url" alt="Image Text"
                       imageClass="bookImg">
                </Image>
              </router-link>

            </template>
          </Column>

          <Column>

            <template #body="slotProps">
              <div class="grid p-3">
                <div class="col-12">
                  <router-link :to="{name: 'book-view', query:{bookId: slotProps.data.id}}">

                    <span class="text-lg font-bold float-left">{{ slotProps.data.title }}</span>

                  </router-link>
                </div>
                <div class="col-12">
                  <router-link :to="{name: 'book-view', query:{bookId: slotProps.data.id}}">

                    <div class="float-left" v-html="slotProps.data.description"></div>
                  </router-link>

                  <Chip class="pl-0 pr-3 mt-3">
                    <span class="ml-2 font-medium">{{ slotProps.data.category }}</span>
                  </Chip>

                </div>

              </div>


            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <Card style="margin-top: 20px" v-if="content">
      {{ content }}

    </Card>
  </div>
  <!--
  <DataView :value="tickets" layout="grid" paginatorPosition="both" :paginator="true" :rows="4">
    <template #header>
      <DataViewLayoutOptions v-model="grid"></DataViewLayoutOptions>
    </template>
    <template #grid="slotProps">

      <div style="padding: .5em" class="col-12 md:col-3">
        <Panel :header="slotProps.data.authorName" style="text-align: center">
          <div>Title: <b>{{ slotProps.data.title }}</b></div>
          <div>Text: <b>{{ slotProps.data.text }}</b></div>
        </Panel>
      </div>

    </template>
  </DataView>
-->

</template>

<script>
/*import UserService from "../services/user.service";*/
import BookService from "../services/book-services";

export default {
  name: "User",
  data() {

    return {
      content: "",
      books: [],
      currentIndex: -1,
    };
  },


  methods: {
    goToBook(bookId) {
      // Navigieren zur Route "/my-data" mit GET-Parametern
      this.$router.push({path: '/book-view', query: {bookId: bookId}})
    },

    retrieveTickets() {
      BookService.getAllBooks()
          .then(response => {
            this.books = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    getImage(imageName) {

      BookService.getImage(imageName)
          .then(response => {
            console.log(response.data);
            return response.data;
          })
          .catch(e => {
            console.log(e);
            return null;
          });
    },


    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);

    },
  },


  mounted() {
    BookService.getAllBooks().then(
        (response) => {
          this.books = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  }
};
</script>

<style scoped>
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .1875rem;
}

.card-img-actions {
  position: relative;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  text-align: center;
}


h5 {
  font-size: 17px;
}

.ticketDetailButton {
  margin-top: 20px;
}

</style>
