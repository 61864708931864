<template>
  <Toast/>


  <Card style="margin-top: 20px" v-if="tickets">
    <template #header>
      <div class="text-center font-bold  text-2xl pt-4">{{ ticketHeader }}</div>


      <Divider/>
    </template>
    <template #title>
      <div class="grid">
        <Button icon="pi pi-plus" class="flex ml-6 p-button-raised" label="Neue Frage erstellen"
                @click="$router.push('../createTickets')">
        </Button>
      </div>


    </template>

    <template #content>


      <div class="flex align-items-stretch flex-wrap card-container blue-container">



        <div class="col-12 md:col-4 lg:col-3" v-for="(nextTicket, index) in tickets" :key="index">
          <Card class="mx-1 my-1 ">
            <template #header>
              <router-link :to="{name: 'ticket-view', params:{id: nextTicket.id}}">

              <Image :src="nextTicket.imageFileInfo.url" alt="Image Text"
                     imageClass="" >
              </Image>
              </router-link>

            </template>
            <template #title>
              <div class="text-lg">
                <router-link class="text-base font-bold" :to="{name: 'ticket-view', params:{id: nextTicket.id}}">

                  {{ nextTicket.title }}
                </router-link>
              </div>
            </template>
            <template #subtitle>
              <div class="flex align-items-center ">
                <Avatar :image="nextTicket.authorImageFileInfo.url" shape="circle" style="padding: 0px; margin: 0px"
                        class="mr-2 col-fixed"/>
                <div class="text-sm col">
                  {{ nextTicket.authorName }} am {{ formatDateNumber(nextTicket.createdOn) }}
                </div>
              </div>
            </template>

            <template #content>
              <router-link class="text-base" :to="{name: 'ticket-view', params:{id: nextTicket.id}}">
                <div v-html="nextTicket.text"></div>

              </router-link>
            </template>
            <template #footer>

              <router-link
                  :to="{name: 'ticket-view', params:{id: nextTicket.id}}">
                <Button icon="pi pi-search" class="p-button-sm p-button-text" label="Frage öffnen"/>

              </router-link>

              <Button style="margin-left: .5em" class="p-button-danger p-button-sm p-button-text" icon="pi pi-trash"
                      @click="confirmDelete(nextTicket.id)"
                      v-if="nextTicket.authorName == currentUser.username">

              </Button>


            </template>
          </Card>
        </div>
      </div>
    </template>
  </Card>

</template>


<script>
import TicketService from "../services/ticket-services";
import TicketServices from "@/services/ticket-services";
import dayjs from "dayjs";

export default {
  name: "User",
  props: {
    ticketHeader: String,
    username: String

  },

  data() {

    return {
      content: "",
      tickets: [],
      currentIndex: -1,
    };
  },


  methods: {
    confirmDelete(deleteId) {
      TicketServices.deleteTicket(deleteId)
          .then(response => {
            console.log(response.data);
            this.$toast.add({severity: 'info', summary: response.data.message});
            this.retrieveTickets();
          })
          .catch(e => {
            console.log(e);
            this.$toast.add({severity: 'info', summary: 'Ticket kann nicht gelöscht werden'});
          });
    },
    retrieveTickets() {
      TicketService.getAllUserTickets(this.$props.username)
          .then(response => {
            this.tickets = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    getImage(imageName) {
      TicketServices.getImage(imageName)
          .then(response => {
            console.log(response.data);
            return response.data;
          })
          .catch(e => {
            console.log(e);
            return null;
          });
    },

    formatDateNumber(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format('DD.MM.YYYY');
    },
  },


  mounted() {
    TicketService.getAllUserTickets(this.$props.username).then(
        (response) => {
          this.tickets = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  }
};
</script>

