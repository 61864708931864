<template>


  <quiz @quiz-completed="handleQuizCompleted" :key="quizKey"/>
  <exercises
      v-show="showModal"
      header="Glückwünsch!"
      :score="score"
      @reload="updateQuiz"
      @close="showModal = false"
  />

</template>

<script>
import Exercises from "@/components/Exercises.vue";
import Quiz from "@/components/Quiz";


export default {
  computed: {},
  components: {Quiz, Exercises},
  name: "App",
  data() {

    return {
      topicName: "",
      categoryName: "",
      quizKey: 0,
      showModal: false,
      score: {
        allQuestions: 0,
        answeredQuestions: 0,
        correctlyAnsweredQuestions: 0,

      },
    };
  },
  methods: {
    handleQuizCompleted(score) {
      this.score = score;
      this.showModal = true;
    },
    updateQuiz() {
      this.showModal = false;
      this.quizKey++;
    },
  },

};
</script>

<style>
* {
  box-sizing: border-box;
}

</style>
