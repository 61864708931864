<template>
  <div class="container">


    <Card style="margin-top: 20px" v-if="categories">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Übungen</div>
        <Divider/>
      </template>
      <template #content>
          <TabView>
            <TabPanel v-for="category in categories" :category="category.id" :key="category.id" :header="category.name">
              <div class="flex flex-wrap justify-content-left">


                <router-link v-for="topic in category.topics" :key="topic.id"
                             :to="{name: 'homework', query:{categoryId: category.id, topicId: topic.id}}">
                  <div
                      class="text-black font-bold border-round m-2 flex border-2 align-items-center justify-content-center"
                      style="min-width: 200px; min-height: 100px;"
                      :style="{'background-color':topic.colorCode}">
                    {{ topic.name }}
                  </div>
                </router-link>
              </div>
            </TabPanel>
          </TabView>
      </template>
    </Card>
    <!--
    <Card style="margin-top: 20px" v-if="categories">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Übungen</div>
        <Divider/>
      </template>
      <template #content>
        <div class="flex flex-wrap justify-content-left">
          <router-link v-for="(category, index) in categories"
                       :key="index" :to="{name: 'test', params:{category: category.name}}">
            <div class="text-black font-bold border-round m-2 flex border-2
          align-items-center justify-content-center"
                 style="min-width: 200px; min-height: 100px;"
                 :style="{'background-color':category.colorCode}"
            >
              {{ category.name }}
            </div>
          </router-link>
        </div>
      </template>
    </Card>
-->
  </div>
</template>

<script>
import QuizServices from "@/services/quiz-services";
import {background, color} from "quill/ui/icons";

export default {
  data() {

    return {
      categories: [],
    };
  },

  methods: {
    color,
    background,
    getCategories() {
      QuizServices.getCategories()
          .then(response => {
            this.categories = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.getCategories();
  }


};
</script>