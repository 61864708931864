<template>
  <div class="container">


    <Card style="margin-top: 10px" class="quiz-card">
      <template #header>
        <div class="text-center font-bold  text-xl pt-4">Übung <strong>"{{ topicName }}"</strong> für {{ categoryName }}
        </div>
        <Divider/>
      </template>

      <template #content>

        <h5 class="text-center" v-html="loading ? 'Keine Übungen vorhanden' : currentQuestion.question"></h5>
        <!-- Only first question is displayed -->
        <form v-if="currentQuestion">

          <Image :src="currentQuestion.questionImageUrl" v-show="!(currentQuestion.questionImageUrl === null)"
                 alt="Image Text" imageStyle="width: 100%; max-width:360px; max-height:245px"
                 imageClass="quizImg" preview/>

          <div v-if="currentQuestion.quizType == 'image'" class="text-center">
            <button
                v-for="answerInfo in currentQuestion.answers"
                :index="currentQuestion.key"
                :key="answerInfo"
                v-html="answerInfo.answer"
                @click.prevent="handleClick"

                v-bind:style="{ backgroundImage: 'url(' + answerInfo.url + ')'}"
                style="min-width: 140px; min-height:140px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color:transparent;
                max-width: 280px;
                height: auto !important;"
            />
          </div>
          <div v-else class="text-center mt-3">
            <button
                v-for="answerInfo in currentQuestion.answers"
                :index="currentQuestion.key"
                :key="answerInfo"
                v-html="answerInfo.answer"
                @click.prevent="handleClick"
            />
          </div>

          <div class="correctAnswers mt-3 w-screen text-center">
            Jetzt hast du {{ index + 1 }} von {{ questions.length }} Fragen geantwortet:
            <strong> Du hast {{ correctAnswers }} richtige {{ pluralizeAnswer }}!</strong>
          </div>

        </form>

      </template>
    </Card>

  </div>
</template>

<script>
import QuizServices from "@/services/quiz-services";

export default {
  data() {
    return {
      topicName: "",
      categoryName: "",
      questions: [],
      loading: true,
      index: 0,
    };
  },
  computed: {

    currentQuestion() {
      if (this.questions !== []) {
        return this.questions[this.index];
      }
      return null;
    },

    correctAnswers() {
      if (this.questions && this.questions.length > 0) {
        let streakCounter = 0;
        this.questions.forEach(function (question) {
          if (!question.rightAnswer) {
            return;
          } else if (question.rightAnswer === true) {
            streakCounter++;
          }
        });
        return streakCounter;
      } else {
        return "--";
      }
    },
    pluralizeAnswer() {
      // For grammatical correctness
      return this.correctAnswers === 1 ? "Antwort" : "Antworten";
    },
    quizCompleted() {
      if (this.questions.length === 0) {
        return false;
      }
      /* Check if all questions have been answered */
      let questionsAnswered = 0;
      this.questions.forEach(function (question) {
        question.rightAnswer !== null ? questionsAnswered++ : null;
      });
      return questionsAnswered === this.questions.length;
    },
    score() {
      if (this.questions !== []) {
        return {
          allQuestions: this.questions.length,
          answeredQuestions: this.questions.reduce((count, currentQuestion) => {
            if (currentQuestion.userAnswer) {
              // userAnswer is set when user has answered a question, no matter if right or wrong
              count++;
            }
            return count;
          }, 0),
          correctlyAnsweredQuestions: this.questions.reduce(
              (count, currentQuestion) => {
                if (currentQuestion.rightAnswer) {
                  // rightAnswer is true, if user answered correctly
                  count++;
                }
                return count;
              },
              0
          ),
          topicName: this.topicName,
          categoryName: this.categoryName,
        };
      } else {
        return {
          allQuestions: 0,
          answeredQuestions: 0,
          correctlyAnsweredQuestions: 0,
          topicName: "",
          categoryName: "",
        };
      }
    },
  },
  watch: {
    quizCompleted(completed) {
      completed &&
      setTimeout(() => {
        this.$emit("quiz-completed", this.score);
      }, 3000);
    },
  },
  methods: {

    filterValuesByKey(question, key) {
      return Object.values(question.urls).filter(value => value[key])
    },

    async fetchQuestions() {
      this.loading = true;
      //fetching questions from api
      // "https://opentdb.com/api.php?amount=5&category=21&type=multiple"
      // "http://localhost:8082/api/quiz/get/" + this.quizCategory

      let response = await fetch(
              QuizServices.searchCategoryAndTopic(this.$route.query.categoryId, this.$route.query.topicId)
          )
      ;
      let index = 0; //To identify single answer
      let data = await response.json();

      this.topicName = data.topicName;
      this.categoryName = data.categoryName;

      let questions = data.results.map((question) => {
        question.answers = question.answerInfoList;

        // images

        //shuffle above array
        for (let i = question.answers.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [question.answers[i], question.answers[j]] = [
            question.answers[j],
            question.answers[i],
          ];
        }

        //add right answers and key
        question.rightAnswer = null;
        question.key = index;
        index++;
        return question;
      });

      this.questions = questions;
      this.loading = false;
    },

    handleClick(e) {
      let index = e.target.getAttribute("index");
      let pollutedUserAnswer = e.target.innerHTML; // innerHTML is polluted with decoded HTML entities e.g ' from &#039;
      /* Clear from pollution with ' */
      let userAnswer = pollutedUserAnswer.replace(/'/, "&#039;");
      //set answer
      this.questions[index].userAnswer = userAnswer;
      /* Set class "clicked" on button with userAnswer -> for CSS Styles; Disable other sibling buttons */
      e.target.classList.add("clicked");
      let allButtons = document.querySelectorAll(`[index="${index}"]`);

      for (let i = 0; i < allButtons.length; i++) {
        if (allButtons[i] === e.target) continue;

        allButtons[i].setAttribute("disabled", "");
      }
      this.checkCorrectAnswer(e, index);
    },

    checkCorrectAnswer(e, index) {
      let question = this.questions[index];
      if (question.userAnswer) {
        if (this.index < this.questions.length - 1) {
          setTimeout(
              function () {
                this.index += 1;
              }.bind(this),
              3000
          );
        }
        if (question.userAnswer === question.correct_answer) {
          /* Set class on Button if user answered right, to celebrate right answer with animation joyfulButton */
          e.target.classList.add("rightAnswer");
          /* Set rightAnswer on question to true, computed property can track a streak out of 20 questions */
          this.questions[index].rightAnswer = true;
        } else {
          /* Mark users answer as wrong answer */
          e.target.classList.add("wrongAnswer");
          this.questions[index].rightAnswer = false;
          /* Show right Answer */
          let correctAnswer = this.questions[index].correct_answer;
          let allButtons = document.querySelectorAll(`[index="${index}"]`);
          allButtons.forEach(function (button) {
            if (button.innerHTML === correctAnswer) {
              button.classList.add("showRightAnswer");
            }
          });
        }
      }
    },
  },
  mounted() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.container {
  /*margin: 1rem auto;*/
  /*padding: 1rem;*/
  /*max-width: 750px;*/
}

form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

button {
  font-size: 14px;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0.3rem;
  width: 40%;
  background-color: rgba(100, 100, 100, 0.3);
  /*border: none;*/
  border-radius: 0.4rem;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  border: 6px solid rgba(100, 100, 100, 0.3);

}

button:hover:enabled {
  transform: scale(1.02);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
  0 3px 1px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:active:enabled {
  transform: scale(1.05);
}

@keyframes flashButton {
  0% {
    opacity: 1;
    transform: scale(1.01);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

button.clicked {
  pointer-events: none;
}

button.rightAnswer {
  animation: flashButton;
  animation-duration: 700ms;
  animation-delay: 200ms;
  animation-iteration-count: 3;
  animation-timing-function: ease-in-out;
  color: black;
  background: linear-gradient(
      210deg,
      rgba(0, 178, 72, 0.25),
      rgba(0, 178, 72, 0.5)
  );
  border: 6px solid green;

}

button.wrongAnswer {
  color: black;
  background: linear-gradient(
      210deg,
      rgba(245, 0, 87, 0.25),
      rgba(245, 0, 87, 0.5)
  );
  border: 6px solid darkred;

}

button.showRightAnswer {
  animation: flashButton;
  animation-duration: 700ms;
  animation-delay: 200ms;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  color: black;
  background: linear-gradient(
      210deg,
      rgba(0, 178, 72, 0.25),
      rgba(0, 178, 72, 0.5)
  );
  border: 6px solid green;

}


</style>
