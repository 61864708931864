<template>
  <Toast/>

  <div class="container">
    <div class="card margin-top-10 p-3" v-if="currentUser && userinfo">
      <div class="grid pr-3">
        <div class="col-12 md:col-6 lg:col-4">

          <div class="col-12 md:col-12 lg:col-12">
            <div class="flex align-items-center justify-content-center">
              <Avatar :image="userinfo.imageFileInfo.url" shape="circle" size="xlarge"
                      class="d-avatar"/>
            </div>

            <Divider/>
            <div class="field grid">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Benutzername</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.username }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.firstname && userinfo.lastname"/>
            <div class="field grid" v-if="userinfo.firstname && userinfo.lastname">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Name</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.firstname }} {{ userinfo.lastname }}
                </div>
              </div>
            </div>

            <!--          <Divider v-if="currentUser.email"/>
                      <div class="field grid" v-if="currentUser.email">
                        <label for="firstname3" class="col-fixed font-bold" style="width:140px">E-Mail</label>
                        <div class="col">
                          <div id="firstname3"
                               class="">
                            {{ currentUser.email }}
                          </div>
                        </div>
                      </div>-->

            <Divider v-if="userinfo.yearOfBirth"/>
            <div class="field grid" v-if="userinfo.yearOfBirth">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Geburtsjahr</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.yearOfBirth }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.profession"/>
            <div class="field grid" v-if="userinfo.profession">
              <label for="firstname3" class="col-fixed font-bold" style="width:140px">Klasse</label>
              <div class="col">
                <div id="firstname3"
                     class="">
                  {{ userinfo.profession }}
                </div>
              </div>
            </div>
            <!--
            <Divider v-if="userinfo.experienceYear"/>
            <div class="field grid" v-if="userinfo.experienceYear">
              <label for="experienceYear" class="col-fixed font-bold" style="width:140px">Erfahrungsjahr</label>
              <div class="col">
                <div id="experienceYear"
                     class="">
                  {{ userinfo.experienceYear }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.address"/>
            <div class="field grid" v-if="userinfo.address">
              <label for="location" class="col-fixed font-bold" style="width:140px">Ort</label>
              <div class="col">
                <div id="location"
                     class="">
                  {{ userinfo.address }}
                </div>
              </div>
            </div>

            <Divider v-if="userinfo.phoneNumber"/>
            <div class="field grid" v-if="userinfo.phoneNumber">
              <label for="location" class="col-fixed font-bold" style="width:140px">Telefonnummer</label>
              <div class="col">
                <div id="location"
                     class="">
                  {{ userinfo.phoneNumber }}
                </div>
              </div>
            </div>
-->
          </div>


        </div>

        <div class="col-12 md:col-6 lg:col-8">
          <Panel header="Über mich">
            <p v-if="userinfo.aboutUser">{{ userinfo.aboutUser }}</p>
          </Panel>

          <Panel header="Fähigkeiten und Kenntnisse" class="margin-top-10">
            <div v-if="userinfo.skills">
              <Tag severity="success" v-for="(next, index) in userinfo.skills" :key="index" :value="next"
                   class="mr-2 text-base" rounded/>
            </div>
          </Panel>

<!--          <Panel header="Erfahrung" class="margin-top-10 erfahrungView">

            <Editor v-if="userinfo.experience" v-model="userinfo.experience" readonly="true"
                    editorStyle="min-height: 190px; border:none">
              <template v-slot:toolbar>
                <span class="ql-formats">
                </span>
              </template>
            </Editor>

          </Panel>-->


        </div>

      </div>
    </div>

    <TicketList :username="userinfo.username" v-if="currentUser && userinfo" ticketHeader="Benutzerfragen"/>


  </div>
</template>

<script>

import UserService from "@/services/user.service";
import TicketList from "@/components/TicketList";


export default {
  name: 'Profile',
  components: {TicketList},

  data() {
    return {
      userinfo: null,
    };
  },


  methods: {

    getUserInfo(id) {

      UserService.getUserInfo(id)
          .then(response => {
                this.userinfo = response.data;
                console.log(response.data);
              }, (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.userinfo = null;
              }
          );
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },


  mounted() {
    console.log(this.$route.params.id);
    this.getUserInfo(this.$route.params.id);
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
}

</script>