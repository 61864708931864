import axios from 'axios';
import authHeader from './auth-header';
import {BASIC_URL} from './global';

const API_URL = BASIC_URL + '/api/book/';
const IMAGE_URL = BASIC_URL + '/images/';


class BookService {

    getAllBooks() {
        return axios.get(API_URL + 'all', {headers: authHeader()});
    }

    getBanner() {
        return axios.get(API_URL + 'banner-list', {headers: authHeader()});
    }

    getImage(imageName) {
        return axios.get(IMAGE_URL + `image/${imageName}`);
    }

    getBook(id) {
        return axios.get(API_URL + `get/${id}`, {headers: authHeader()});
    }

    find(bookId) {
        return axios.get(API_URL + `find/?bookId=${bookId}`, {headers: authHeader()});
    }


    getAllImages() {
        return axios.get(API_URL + 'images', {headers: authHeader()});
    }


}

export default new BookService();
