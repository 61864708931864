<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted" style="margin-top: 30px; background: #ebebeb">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">

      </div>
      <!-- Left -->

      <!-- Right -->
      <div>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-google"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-github"></i>
        </a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-gem me-3"></i>Unsere Ziele
            </h6>
            <p>
              Unser Verein bietet Nachhilfe und bilinguale Sprachförderung für
              deutsch-mongolischen Kinder und Jugendliche in ganz Baden-Württemberg. Im Rahmen des Projekts wird ein
              innovatives Tool entwickelt, das ein spielerisch-interaktives Lernen ermöglicht.
            </p>
          </div>
          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->

            <h6 class="text-uppercase fw-bold mb-4">
              Information
            </h6>

            <p>
              <router-link class="text-reset" to="/impressum">
                Kontakt
              </router-link>

            </p>

            <p>
              <a class="text-reset"></a>
              <router-link class="text-reset" to="/project">
                Über Tool
              </router-link>
            </p>


            <p>
              <router-link class="text-reset" to="/datenschutz">
                Datenschutzerklärung
              </router-link>

            </p>
            <p>
              <a href="https://vma-ev.com/" class="text-reset">Unser Verein</a>
            </p>
            <p>
              <router-link class="text-reset" to="/impressum">
                Impressum
              </router-link>

            </p>

          </div>
          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Entwicklerteam
            </h6>
            <p>
              <a href="#!" class="text-reset">Gunee</a>
            </p>
            <p>
              <a href="#!" class="text-reset">Oyuntulkhuur</a>
            </p>
            <p>
              <a href="#!" class="text-reset">Khaliun</a>
            </p>
            <p>
              <a href="#!" class="text-reset">Dajaa</a>
            </p>

            <p>
              <a href="#!" class="text-reset">Tuvshin</a>
            </p>

          </div>
          <!-- Grid column -->

          <!-- Grid column -->

          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4" style="padding: 5px !important!;">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Kontakt</h6>

            <div>VMA e.V.</div>

            <p><i class="fas fa-home me-3"></i>Pfaffenwaldring 60 <br>
              70569 Stuttgart, Deutschland</p>
            <p>
              <i class="fas fa-envelope me-3"></i>
              info@vma-ev.com
            </p>
            <p><i class="fas fa-phone me-3"></i> + 49 176 8109 6251</p>
            <p>
              <a href="https://vma-ev.com/" class="text-reset">www.vma-ev.com</a>
            </p>

          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="copyRight">
      © 2023 Copyright:
      <a class="text-reset fw-bold" href="https://vma-ev.com/">VMA e.V.</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->


  <Dialog v-model:visible="cookieDialogVisible" header="Cookie Hinweis für Digital-Learn.net"
          :style="{ width: '900px' }" position="bottomright"

          :draggable="false">
    <p class="m-0">Wir verwenden Cookies, die für die Website "Digital Trifft Lernspass" unbedingt erforderlich sind.
      Wenn Sie Ihre Zustimmung erteilen, verwenden wir zusätzliche Cookies, um z.B. zum Zwecke der Statistik oder des
      Marketings Informationen zu Ihrer Nutzung unserer Website zu verarbeiten. Detaillierte Informationen zu diesen
      Cookies finden Sie in unserer Erklärung zum Datenschutz. Ihre Zustimmung ist freiwillig und für die Nutzung der
      Website nicht notwendig. Durch Klick auf “Ich stimme zu“ willigen Sie allen zusätzlichen Cookies gleichzeitig zu.
      Sie können Ihre Zustimmung jederzeit über den Link „Cookie-Einstellungen anpassen“ im Footer auf jeder Seite
      widerrufen oder dort Ihre Cookie-Einstellungen ändern. Mit Klick auf “zusätzliche Cookies ablehnen“ werden alle
      zusätzlichen Cookies abgelehnt.<br/>
      <router-link class="text-reset underline" to="/datenschutz">Datenschutzerklärung</router-link>
    </p>
    <template #footer>
      <Button label="Ablehnen" icon="pi pi-times" class="p-button-danger" @click="removeCookie" text/>
      <Button label="Akzeptieren" icon="pi pi-check" class="p-button-success" @click="saveCookie" autofocus/>


    </template>
  </Dialog>

</template>


<script>


export default {

  data() {

    return {
      cookieDialogVisible: true,
    };
  },
  computed: {},

  methods: {
    saveCookie() {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + 1)
      this.$cookies.set('CookieAccepted', 'true', {
        expires: expirationDate
      })
      this.cookieDialogVisible = false;
    },

    removeCookie() {
      this.$cookies.remove('CookieAccepted');
      this.cookieDialogVisible = false;
    }
  },
  mounted() {
    if (this.$cookies.get('CookieAccepted') == 'true') {
      this.cookieDialogVisible = false;
    } else {
      this.cookieDialogVisible = true;
    }
  }
};
</script>

<style>

.copyRight {
  background-color: #00576f;
  height: 30px;
  /* text-align: left; */
  color: white;
  padding: 5px;
  font-size: 14px;
}
</style>