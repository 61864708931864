import axios from 'axios';
import authHeader from './auth-header';
import {BASIC_URL} from './global';
const API_URL = BASIC_URL +'/api/test/';


class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getUserInfo(id) {
    return axios.get(API_URL + `get/${id}`, {headers: authHeader()});
  }

  updateUserInfo(data){
    return axios.post(API_URL + 'update', data, {headers: authHeader()});
  }

  search(key) {
    return axios.get(API_URL + `find/?key=${key}`, {headers: authHeader()});
  }

  getUserSkills() {
    return axios.get(API_URL + 'skills', { headers: authHeader() });
  }

  getUserProfessions() {
    return axios.get(API_URL + 'professions', { headers: authHeader() });
  }

  uploadImages(file) {
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(API_URL + 'upload', formData,
        {
          headers: authHeader(),
          "Content-type": "application/json"
        });
  }


}

export default new UserService();
