<template>
  <div class="container">

    <Card style="margin-top: 10px" v-if="quizCategory">
      <template #header>

        <div class="text-center font-bold  text-xl pt-4">Übung <strong>"{{ quizCategory }}"</strong></div>

        <Divider/>
      </template>
      <template #content >

        <quiz @quiz-completed="handleQuizCompleted" :key="quizKey"/>
        <exercises
            v-show="showModal"
            header="Glückwünsch!"
            :score="score"
            @reload="updateQuiz"
            @close="showModal = false"
        />
      </template>
    </Card>

  </div>
</template>

<script>
import Exercises from "@/components/Exercises.vue";
import Quiz from "@/components/Quiz";


export default {
  computed: {
    quizCategory() {
      return this.$route.params.category
    },
  },
  components: {Quiz, Exercises},
  name: "App",
  data() {
    return {
      quizKey: 0,
      showModal: false,
      score: {
        allQuestions: 0,
        answeredQuestions: 0,
        correctlyAnsweredQuestions: 0,
      },
    };
  },
  methods: {
    handleQuizCompleted(score) {
      this.score = score;
      this.showModal = true;
    },
    updateQuiz() {
      this.showModal = false;
      this.quizKey++;
    },
  },

};
</script>

<style>
* {
  box-sizing: border-box;
}

</style>
