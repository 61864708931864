<template>
  <div id="app">
    <div class="headerMenu">

      <div class="container">
        <nav class="navbar navbar-expand-lg headerMenu">
          <div class="container headerContainer">
            <div class="float-left">

              Willkommen in Solongo Schule
            </div>


            <div class="float-right" style="padding-right: 8px">
              Bei Fragen einfach anrufen: +49 17681096251
            </div>
          </div>
        </nav>
      </div>
    </div>
<!--    <div class="accessHeader">
      <div class="container">

        <nav class="navbar navbar-expand-lg no-box-shadow">

        </nav>
      </div>
    </div>-->
    <div class="featureHeader">
      <div class="container ">
        <nav class="navbar navbar-expand-lg no-box-shadow">

          <router-link to="/home">
            <img src="./assets/solongo.jpeg" class="imageHeader flex align-content-start">
          </router-link>


          <div class="navbar-nav mr-auto">

            <router-link  to="/home" class="nav-item nav-link">Home</router-link>
            <router-link  to="/books" class="nav-item nav-link">Wissen</router-link>
            <router-link  to="/lessons" class="nav-item nav-link">Übungen</router-link>
            <router-link  to="/tickets" class="nav-item nav-link">Förderfragen</router-link>
            <router-link v-if="currentUser" to="/members" class="nav-item nav-link">Schulfreunde</router-link>
            <router-link v-else to="/login" class="nav-item nav-link">Schulfreunde</router-link>

<!--            <router-link v-if="showModeratorBoard" to="/mod" class="nav-item nav-link">Moderator</router-link>-->
<!--            <router-link v-if="showAdminBoard" to="/admin" class="nav-link">Admin</router-link>-->




          </div>

          <div v-if="!currentUser" class="navbar-nav ml-auto">
<!--            <router-link to="/register" class="nav-item nav-link">-->
<!--              <font-awesome-icon icon="user-plus"/>-->
<!--              Registrieren-->
<!--            </router-link>-->

            <router-link to="/login" class="nav-item nav-link">
              <font-awesome-icon icon="sign-in-alt"/>
              Anmelden
            </router-link>
          </div>

          <div v-if="currentUser" class="navbar-nav ml-auto">

            <li class="nav-item">
              <router-link v-if="currentUser.avatarUploaded" to="/my-profile" class="nav-link" style="padding: 0px">
                <Avatar :image="currentUser.imageFileInfo.url" shape="circle"/>
              </router-link>
              <router-link v-else to="/my-profile" class="nav-link">
                <font-awesome-icon icon="user"/>
                {{ currentUser.username }}
              </router-link>
            </li>

            <li class="nav-item">
              <a class="nav-link abmeldenButton" @click.prevent="logOut">
                <font-awesome-icon icon="sign-out-alt"/>
                Abmelden
              </a>
            </li>
          </div>
        </nav>

      </div>
    </div>

    <div class="bottomMenu d-none d-sm-block">
    </div>


    <router-view/>


    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {

  components: {Footer},

  data() {
    return {};
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {

  }
};
</script>
<style>
#app {


  /*  font-family: 'Lobster', Helvetica, Arial, sans-serif;

  text-align: center;
  color: #2c3e50;*/
}


</style>