<template>

  <div class="container">

    <Card style="margin-top: 20px">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Datenschutz-Erklärung</div>
      </template>


      <template #content>
        <div class="">
          Wir nehmen den Schutz deiner persönlichen Daten sehr ernst. Diese Datenschutz-Erklärung soll dir einen
          Überblick darüber geben, wie wir deine Daten erfassen, verwenden und schützen. Bitte nimm dir einen Moment
          Zeit, um die nachstehenden Informationen sorgfältig zu lesen.
          <br>
          <ul class="mt-5">
            <li class="font-bold">Verwendung von Cookies:</li>
            Wir verwenden Cookies ausschließlich für angemeldete Benutzer, um das Benutzererlebnis auf unserer Website
            zu verbessern. Cookies sind kleine Textdateien, die auf deinem Gerät gespeichert werden. Sie ermöglichen es
            uns, dich zu erkennen und bestimmte Einstellungen zu speichern. Du kannst die Verwendung von Cookies in
            deinen Browsereinstellungen kontrollieren.

            Bitte beachte, dass Cookies für die korrekte Funktionalität und Sicherheit unserer Website wichtig sind,
            insbesondere für angemeldete Benutzer. Durch die Nutzung unserer Website stimmst du der Verwendung von
            Cookies gemäß dieser Datenschutz-Erklärung zu.
            <li class="font-bold mt-3">Datenweitergabe:</li>
            Wir geben deine persönlichen Daten niemals ohne deine ausdrückliche Einwilligung an Dritte weiter, es sei
            denn, dies ist gesetzlich vorgeschrieben oder zur Erfüllung unserer vertraglichen Verpflichtungen
            erforderlich. Wir schützen deine Daten und setzen angemessene Sicherheitsmaßnahmen ein, um unbefugten
            Zugriff oder Missbrauch zu verhindern.
            <li class="font-bold mt-3">Externe Links:</li>
            Unsere Website kann Links zu externen Websites enthalten, für die wir nicht verantwortlich sind. Bitte
            beachte, dass diese Websites ihre eigenen Datenschutzrichtlinien haben, auf die wir keinen Einfluss haben.
            Wir empfehlen dir, die Datenschutzerklärungen dieser Websites zu lesen, wenn du sie besuchst.
            <li class="font-bold mt-3">Deine Rechte:</li>
            Du hast das Recht, auf deine persönlichen Daten zuzugreifen, sie zu korrigieren oder zu löschen. Wenn du
            Fragen oder Bedenken hinsichtlich der Verwendung deiner Daten hast, kannst du dich gerne an uns wenden. Wir
            werden uns bemühen, deine Anfragen so schnell wie möglich zu bearbeiten.
          </ul>
          Durch die Nutzung unserer Website stimmst du der Erfassung und Verwendung deiner Daten gemäß dieser
          Datenschutz-Erklärung zu.
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      impressum: "",
    };
  },
  mounted() {

  },
};
</script>
<style>


</style>
