<template>
  <Toast/>

  <Card v-if="content" style="margin-top: 12px">
    {{ content }}
  </Card>

  <div class="container">


    <Card v-if="ticket" class="pl-8 pr-8 mt-3">
      <template #header>

      </template>
      <template #content>
        <div class="vertcal-align-middle">
          <Avatar :image="ticket.authorImageFileInfo.url" size="large" shape="circle"
                  style="padding: 0px; margin: 0px; vertical-align:middle"
                  class="mr-2 col-fixed"/>
          <span class="ml-2"><strong>{{ ticket.authorName }}</strong></span> am {{
            formatDateNumber(ticket.createdOn)
          }}
        </div>
        <Divider/>
        <div class="font-bold  text-xl ">{{ ticket.title }}</div>
        <div class="mt-2" v-html="ticket.text"></div>

        <div class="mt-2 grid">
          <div class="col-12 md:col-10 lg:col-8">
            <Image :src="ticket.imageFileInfo.url" alt="Image Text" imageStyle="width: 100%; max-width:360px"
                   imageClass="ticketViewImg" preview/>
          </div>
        </div>
        <Divider></Divider>
        <div class="mt-2">

          <span> Diese Frage hat <strong>{{ comments.length }}</strong> Antworten:</span>
        </div>

      </template>
    </Card>

    <Card class=" pl-8 pr-8 mt-3" v-if="!currentUser">
      <template #header>
        <div class="vertcal-align-middle" style="padding-top: 10px; padding-bottom: 11px">
          <div class="text-center font-bold  text-xl pt-4">Neue Antwort Schreiben</div>
        </div>
        <Divider/>
      </template>
      <template #content>

        <Button class="p-button-success" icon="pi pi-check " style="width: auto"
                @click="$router.push('/login')"
                label="Anmelden und Antwort Schreiben"/>
      </template>
    </Card>


    <Card class=" pl-8 pr-8 mt-3" v-if="currentUser">
      <template #header>
        <div class="vertcal-align-middle" style="padding-top: 10px; padding-bottom: 11px">
          <div class="text-center font-bold  text-xl pt-4">Neue Antwort Schreiben</div>
        </div>
        <Divider/>
      </template>

      <template #content>
        <Editor v-model="commentText" placeholder="Antwort beschreiben" class="mb-3"
                editorStyle="min-height: 190px;">
          <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
            </span>

            <span class="ql-formats">
                    <button class="ql-list" value="ordered" type="button"></button>
                    <button class="ql-list" value="bullet" type="button"></button>
                    <select class="ql-align">
                        <option defaultValue></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>

                </span>
            <span class="ql-formats">
                    <button class="ql-clean" type="button"></button>
                    <button class="ql-image" type="button"></button>
                </span>


          </template>


        </Editor>

        <div class="mt-3">
          <Button @click="postComment" class="p-button-success" icon="pi pi-check " style="width: auto"
                  v-if="currentUser"
                  label="Antwort posten"/>


        </div>
      </template>
    </Card>

    <Card class="pl-8 pr-8 mt-3">
      <template #header>
        <div class="vertcal-align-left" style="padding-top: 10px;">
          <div class="text-center font-bold  text-xl pt-4">{{ comments.length }} Antworten vorhanden</div>
        </div>
      </template>
    </Card>

    <Card class="pl-8 pr-8 mt-3" v-for="(nextComment, index) in comments.slice().reverse()" :key="index">
      <template #header>
        <div class="vertcal-align-middle pt-3">
          <Avatar :image="nextComment.authorImageFileInfo.url" size="large" shape="circle"
                  style="padding: 0px; margin: 0px; vertical-align:middle"
                  class="col-fixed"/>
          <span class="ml-2"><strong>{{ nextComment.author }}</strong></span> am {{
            formatDateNumber(nextComment.createdOn)
          }}
        </div>
        <Divider/>
      </template>

      <template #content>
        <div id="commentContent">
          <div class="" v-html="nextComment.text"></div>
        </div>
      </template>

    </Card>

  </div>

</template>

<script>
import TicketServices from "@/services/ticket-services";
import dayjs from "dayjs";

export default {


  data() {
    return {
      ticket: null,
      content: "",
      comments: [],
      commentText: "",
    };
  },

  methods: {


    formatDateNumber(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format('DD.MM.YYYY');
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);

    },
    refreshTicket() {
      this.getTicket(this.$route.params.id);
    },

    postComment() {
      var comment = {
        text: this.commentText,
        author: this.$store.state.auth.user.username,
        userId: this.$store.state.auth.user.id,
        ticketId: this.ticket.id,
      };

      if (this.commentText === '') {
        this.$toast.add({severity: 'error', summary: 'Bitte Antwort schreiben'});
        return;
      }

      TicketServices.comment(comment)
          .then(response => {
            this.comments = response.data.comments;
            this.commentText = "";
            this.$toast.add({severity: 'success', summary: 'Antwort gepostet'});
            console.log(response.data);
          }).catch(e => {
        console.log(e);
      });
    },

    getTicket(id) {
      TicketServices.getTicket(id)
          .then(response => {
                this.ticket = response.data;
                this.comments = this.ticket.comments;
                this.commentText = "";

                console.log(response.data);
              }, (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.ticket = null;
              }
          );
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }


  },


  computed: {
    ticketNumber() {
      return parseInt(this.$route.params.id)
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    this.message = '';
    this.refreshTicket();

  }
}

</script>

<style>
#commentContent img {
  max-width: 450px;
}
</style>
