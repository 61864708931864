<template>
  <div class="container">
    <Card v-if="content" style="margin-top: 12px">
      {{ content }}
    </Card>

    <Card v-if="book" style="margin-top: 12px">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">{{ book.title }}</div>
        <Divider/>

      </template>
      <template #content>
        <Chip class="pl-0 pr-3">
          <span class="ml-2 font-medium">{{book.category}}</span>
        </Chip>

        <!--
        <span class="ml-2"><strong>{{ book.authorName }}</strong></span> am {{
          formatDateNumber(book.createdOn)
        }}-->
        <div class="mt-2 ml-2" v-html="book.text"></div>

          <Button icon="pi pi-pencil" class="flex p-button-raised float-right mb-5" :label="getUebung(book.topic)"
                  @click="goToHomework(0, book.topicId)">
          </Button>


        <Divider/>

        <Galleria :value="book.bookImages" v-model:activeIndex="activeIndex"
                  containerStyle="max-width: 50%"
                  :responsiveOptions="responsiveOptions"
                  :numVisible="7"
                  :circular="true" :fullScreen="true"
                  :showItemNavigators="true" :showThumbnails="false"
                  v-model:visible="displayBasic">
          <template #item="slotProps">
            <img :src="slotProps.item.url" :alt="slotProps.item.name" style="width: 100%; display: block;"  />
          </template>
          <template #thumbnail="slotProps">
            <img :src="slotProps.item.url" :alt="slotProps.item.name" style="display: block;"/>
          </template>
        </Galleria>

        <div v-if="book.bookImages" class="grid">
          <div v-for="(image, index) of book.bookImages" class="col-12 md:col-3 lg:col-3 " :key="index">
            <img :src="image.url" :alt="image.name"
                 style="cursor: pointer;width: 100%" class="p-2 img-thumbnail" @click="imageClick(index)"/>
          </div>
        </div>

      </template>
    </Card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import BookServices from "@/services/book-services";
export default {

  data() {
    return {
      book: null,
      content: "",
      activeIndex: 0,
      displayCustom: false,
      displayBasic: false,

      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 5
        },
        {
          breakpoint: '768px',
          numVisible: 3
        },
        {
          breakpoint: '560px',
          numVisible: 1
        }
      ],
    };
  },

  methods: {

    goToHomework(categoryId, topicId) {
      // Navigieren zur Route "/my-data" mit GET-Parametern
      this.$router.push({ path: '/homework', query: { categoryId: categoryId, topicId: topicId } })
    },

    imageClick(index) {
      this.activeIndex = index;
      // this.displayCustom = true;
      this.displayBasic = true
    },
    formatDateNumber(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format('DD.MM.YYYY');
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },

    loadBook() {
      this.getBook(this.$route.query.bookId);
    },
    getUebung(category) {
      return  'Übungen "'+category+'"';
    },

    getBook(bookId) {
      BookServices.find(bookId)
          .then(response => {
                this.book = response.data;
                console.log(response.data);
              }, (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.book = null;
              }
          );
    },


  },


  computed: {
    ticketNumber() {
      return parseInt(this.$route.params.id)
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    this.message = '';
    this.loadBook();
  }
}

</script>
