<template>
  <Toast/>
  <div class="container">

    <Card style="margin-top: 20px" v-if="currentUser && !submitted">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Neue Förderfrage</div>
      </template>
      <template #content>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12 ">
            <Message severity="error" class="mt-0 " v-if="message">{{ message }}</Message>
            <InputText id="title" v-model="ticket.title" placeholder="Fragetitel hier eingeben" class="mb-3"/>
            <!--          <Textarea id="description" v-model="ticket.text" placeholder="Frage beschreiben" class="mb-3"/>-->

            <Editor v-model="ticket.text" placeholder="Frage beschreiben" class="mb-3" editorStyle="min-height: 190px;">
              <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
            </span>

                <span class="ql-formats">
                    <button class="ql-list" value="ordered" type="button"></button>
                    <button class="ql-list" value="bullet" type="button"></button>
                    <select class="ql-align">
                        <option defaultValue></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" type="button"></button>
                </span>
              </template>


            </Editor>
            <div class="grid">
              <div class="col-12 md:col-8 lg:col-8">

                <FileUpload name="demo[]"
                            chooseLabel="Bild auswählen" upload-label="Hochladen" cancel-label="Abbrechen"
                            :customUpload="true"
                            @uploader="primeUpload"/>

                <div class="mt-3">
                  <Button @click="save" class="p-button-success" icon="pi pi-check " style="width: auto"
                          label="Frage erstellen"/>
                  <Button label="Abbrechen" icon="pi pi-times" class="ml-3 p-button-secondary" @click="$router.back()"
                          style="width: auto"/>

                </div>
              </div>
              <div class="col-12 md:col-4 lg:col-4">
                <div v-if="uploadImage">
                  <Image :src="uploadImage.url" imageStyle="width: 100%"
                         imageClass="img-thumbnail" preview>
                  </Image>
                </div>
              </div>

            </div>
          </div>

        </div>

      </template>

    </Card>


    <Card style="margin-top: 20px" v-if="currentUser && submitted">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Deine Frage wurde gerade erstellt.</div>
      </template>
      <template #content>

        <div class="mt-3">

          <router-link :to="{name: 'ticket-view', params:{id: ticket.id}}">
            <Button class="p-button-success" icon="pi pi-external-link" style="width: auto"
                    label="Deine Frage öffnen"/>
          </router-link>

          <Button @click="newTicket" class="p-button-success ml-3 " icon="pi pi-check" style="width: auto"
                  label="Neue Frage erstellen"/>
        </div>
      </template>
    </Card>

    {{ content }}

  </div>
</template>

<script>
import TicketServices from "@/services/ticket-services";
// import GoBack from '@/components/GoBack.vue'

export default {
  // components: {GoBack},

  data() {
    return {
      content: "",
      ticket: {
        id: null,
        title: "",
        text: ""
      },
      submitted: false,
      message: "",
      messageUpload: "",
      uploadImage: {
        name: "",
        url: ""
      }
    };
  },

  methods: {
    save() {
      if (this.ticket.title === '') {
        this.message = "Bitte Titel und Beschreibung eingeben";
        return;
      }
      if (this.ticket.text === '') {
        this.message = "Bitte Beschreibung eingeben";
        return;
      }

      var data = {
        title: this.ticket.title,
        text: this.ticket.text,
        authorName: this.$store.state.auth.user.username,
        previewImageName: this.uploadImage.name
      };

      TicketServices.create(data)
          .then(response => {
            this.ticket.id = response.data.id;

            console.log(response.data);
            this.submitted = true;
          })
          .catch(e => {
            console.log(e);
          });


    },

    newTicket() {
      this.submitted = false;
      this.ticket = {
        id: null,
        title: "",
        text: "",
      };
      this.message = "";
      this.messageUpload = "";
      this.uploadImage = {
        name: "",
        url: ""
      }
    },


    primeUpload(event) {
      TicketServices.uploadImages(event.files[0])
          .then(response => {
            this.uploadImage = response.data;
          })
          .catch(() => {
            this.messageUpload = "Bild kann nicht hochgeladen werden";
          });
    }
  },
  mounted() {
    TicketServices.getAllImages().then(response => {
          this.fileInfos = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  }

}

</script>
