import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/themes/fluent-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "./assets/main.css"
import 'primeflex/primeflex.css';
import {FontAwesomeIcon} from './plugins/font-awesome';
// PrimeVue
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Fieldset from 'primevue/fieldset';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import DataView from 'primevue/dataview';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import Avatar from 'primevue/avatar';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
import ConfirmPopup from 'primevue/confirmpopup';
import Editor from 'primevue/editor';
import Calendar from 'primevue/calendar';
import Chips from 'primevue/chips';
import InputMask from 'primevue/inputmask';
import Message from 'primevue/message';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Galleria from 'primevue/galleria';
import Dropdown from 'primevue/dropdown';
import Chip from 'primevue/chip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import VueCookies from 'vue-cookies'




const app = createApp(App);

app.use(PrimeVue);
app.use(router);
app.use(store);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueCookies);

app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('InputText', InputText);
app.component('Dropdown', Dropdown);
app.component('Button', Button);
app.component('Toast', Toast);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('FileUpload', FileUpload);
app.component('DataView', DataView);
app.component('Panel', Panel);
app.component('Card', Card);
app.component('Avatar', Avatar);
app.component('Divider', Divider);
app.component('Dialog', Dialog);
app.component('Fieldset', Fieldset);
app.component('Tag', Tag);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Editor', Editor);
app.component('Calendar', Calendar);
app.component('Textarea', Textarea);
app.component('Chips', Chips);
app.component('InputMask', InputMask);
app.component('Message', Message);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('Chip', Chip);


app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
