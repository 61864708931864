import axios from "axios";
import {BASIC_URL} from './global';

class QuizService {


    getQuizList(category) {
        return BASIC_URL + '/api/quiz/get/' + category;
    }

    getCategories() {
        return axios.get(BASIC_URL + '/api/quiz/categories');
    }

    searchCategoryAndTopic(categoryId, topicId) {
        return BASIC_URL + '/api/quiz/' + 'find?categoryId='+ categoryId+'&topicId='+ topicId;
    }




}

export default new QuizService();
