<template>
  <Toast/>

  <div class="container">

    <Card style="margin-top: 20px" v-if="members">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Netzwerk von Schüler und Lehrer</div>
      </template>


      <template #content>
        <div class="grid">

          <div class="col-12 md:col-12 mb-12" style="">
            <div class="p-inputgroup">
              <InputText v-model="key" placeholder="Suche nach Skills oder Kenntnisse" v-on:keyup.enter="search"/>
              <Button icon="pi pi-search" class="p-button-warning" @click="search"/>
            </div>
          </div>
        </div>
        <div class="grid mt-5">
          <div class="col-12 md:col-6 lg:col-4" style="">
            <Panel header="Gesuchte Skills">
              <Button v-for="(skill, index) in skills" :key="index" style=""
                      class="p-button-sm p-button-rounded skillButton"
                      :label="skill" @click="searchBy(skill)"/>
            </Panel>

            <Panel header="Schulklassen" class="mt-5">
              <Button v-for="(profession, index) in professions" :key="index" style=""
                      class="p-button-sm p-button-text p-button-link professionButton p-0 mb-3"
                      :label="profession.name" @click="searchBy(profession.name)"/>
            </Panel>

          </div>
          <div class="col-12 md:col-6 lg:col-8 lg:pl-6 xl:pl-6 md:pl-2 sm:pl-0" style="">
            <span v-if="members.length > 0 && key!=''">{{ members.length }}
              Mitglieder passen zu Deinen Suchkriterien:</span>
            <span v-if="members.length == 0">Keine Mitglieder gefunden:</span>
            <span v-if="members.length > 0 && key==''">Alle Mitglieder</span>

            <Button v-if="key!=''" :label="key" icon="pi pi-times"
                    style=""  @click="searchBy('')"
                    class="p-button-sm p-button-warning resetButton"/>


            <Divider style="margin-top: 30px"/>

            <div class="col-12 md:col-12 lg:col-12" v-for="(member, index) in members" :key="index">
              <div class="grid">
                <div class="col-12 md:col-2 lg:col-2">
                  <div class="grid">
                    <div class="col-fixed">
                      <router-link :to="{name: 'user-profile', params:{id: member.id}}">
                        <Avatar :image="member.imageFileInfo.url" size="large" shape="circle"
                                class="d-avatar"/>
                      </router-link>
                    </div>
                  </div>

                </div>
                <div class="col-12 md:col-12 lg:col-10">

                  <router-link :to="{name: 'user-profile', params:{id: member.id}}">

                    <div class="col ml-3" style="min-width: 300px">
                      <div class="field grid text-l font-bold" v-if="member.firstname && member.lastname">
                        {{ member.firstname }} {{ member.lastname }}
                      </div>
                      <div class="field grid" style="margin-bottom: 0px" v-if="member.yearOfBirth">
                        <label for="profession" class="col-fixed font-bold memberInfo">Geburtsjahr</label>
                        <div class="col">
                          <div id="yearOfBirth"
                               class="">
                            {{ member.yearOfBirth }}
                          </div>
                        </div>
                      </div>

<!--
                      <div class="field grid" style="margin-bottom: 0px" v-if="member.education">
                        <label for="education" class="col-fixed font-bold memberInfo"
                        >Beruf</label>
                        <div class="col">
                          <div id="education"
                               class="">
                            {{ member.education }}
                          </div>
                        </div>
                      </div>

                      <div class="field grid" style="margin-bottom: 0px" v-if="member.profession">
                        <label for="profession" class="col-fixed font-bold memberInfo"
                        >Branche</label>
                        <div class="col">
                          <div id="profession"
                               class="">
                            {{ member.profession }}
                          </div>
                        </div>
                      </div>



                      <div class="field grid" style="margin-bottom: 0px" v-if="member.experienceYear">
                        <label for="profession" class="col-fixed font-bold memberInfo">Erfahrung</label>
                        <div class="col">
                          <div id="experienceYear"
                               class="">
                            {{ member.experienceYear }} Jahre
                          </div>
                        </div>
                      </div>

                      <div class="field grid" style="margin-bottom: 0px" v-if="member.address">
                        <label for="profession" class="col-fixed font-bold memberInfo">Ort</label>
                        <div class="col">
                          <div id="address"
                               class="">
                            {{ member.address }}
                          </div>
                        </div>
                      </div>

                      <div class="field grid" style="margin-bottom: 0px" v-if="member.phoneNumber">
                        <label for="profession" class="col-fixed font-bold memberInfo">Telefonnummer</label>
                        <div class="col">
                          <div id="phoneNumber"
                               class="">
                            {{ member.phoneNumber }}
                          </div>
                        </div>
                      </div>
-->
                      <div class="field grid" style="margin-bottom: 0px" v-if="member.skills">
                        <label for="profession" class="col-fixed font-bold memberInfo">Fähigkeiten</label>
                        <div class="col">
                          <div id="skills"
                               class="">
                            <Tag severity="success" v-for="(next, index) in member.skills" :key="index" :value="next"
                                 class="mr-2 " rounded/>
                          </div>
                        </div>
                      </div>

                      <div class="field grid" style="margin-bottom: 0px">
                        <router-link class="mt-3" :to="{name: 'user-profile', params:{id: member.id}}">
                          <Button icon="pi pi-search " class="p-button-sm " label="Mehr erfahren"/>
                        </router-link>
                      </div>


                    </div>
                  </router-link>
                </div>


              </div>
              <Divider/>

            </div>
          </div>
        </div>


      </template>
    </Card>
  </div>
</template>


<script>
import UserService from "@/services/user.service";

export default {

  data() {

    return {
      members: [],
      skills: [],
      professions: [],
      key: ""
    };
  },


  methods: {

    searchBy(searchKey) {
      this.key = searchKey;
      this.search();
      window.scrollTo({
        top: 250,
        left: 0,
        behavior: 'smooth'
      });
    },

    search() {
      UserService.search(this.key)
          .then(response => {
            this.members = response.data.users;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },
    getProfessions() {
      UserService.getUserProfessions()
          .then(response => {
            this.professions = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },
    getSkills() {
      UserService.getUserSkills()
          .then(response => {
            this.skills = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },


  },


  mounted() {
    this.search();
    this.getSkills();
    this.getProfessions();
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  }
};
</script>

<style>
.skillButton {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.professionButton {
  width: 100%;
  text-align: left !important;
  margin-bottom: 5px !important;
}

.resetButton{
  padding: 2px 4px 2px 4px !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
</style>

