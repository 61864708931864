<template>

  <div class="container">

    <Card style="margin-top: 20px">
      <template #header>
        <div class="text-center font-bold  text-2xl pt-4">Projektziele</div>
      </template>


      <template #content>
        <div class="">
          Im Rahmen des Projekts wird ein innovatives, interaktives digitales Tool entwickelt, das einer- seits
          spielerisch-interaktives Lernen und andererseits ortsunabhängige Lernbetreuung ermöglicht. Mithilfe dieses
          Tools können unsere Ehrenamtlichen die Kinder und Jugendliche, die pande- miebedingt Bildungsrückstände
          aufzeigen, zielgerichtet, effizient und individuell fördern.<br/>
          <ul class="mt-5">
            <li class="font-bold">Selbstlernen der Kinder</li>
            Selbstlernen der Kinder können durch dieses Tool gefördert werden, damit sie pandemiebedingte Rückstände
            schnell aufholen können.
            <li class="font-bold">Lehrkraft</li>
            Die Ehrenamtlichen können mit dem Tool interaktiv mit den Lernenden kosten- und zeitsparend
            zusammenarbeiten.
            <li class="font-bold">Hausaufgabe</li>
            Die Hausaufgabenbetreuungen können durch das Tool automatisiert unterstützt werden.
            <li class="font-bold">Hilfe</li>

            Mithilfe des digitalen, spielerisch-interaktiven Tools soll der Lernspass, Interesse und Motivation der
            Lernenden erhöht werden. Auch unseren Ehrenamtlichen werden in der Lage
            sein, ortsunabhängig und zeitsparend jedes Kind und jede/n Jugendlichen individuell
            beim Lernen zu unterstützen und zu fördern.
            <li class="font-bold">Nachhaltiges Software</li>
            Ein nachhaltiges Softwareprodukt, das hauptsächlich erweiterbar und wiederverwendbar
            ist, muss entwickelt werden.
          </ul>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      impressum: "",
    };
  },
  mounted() {

  },
};
</script>
<style>


</style>
