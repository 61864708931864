<template>

  <div class="homeSection">

    <div class="hero-text">
      <h1 style="font-size:50px">{{ content }}
        <img class="foerderung" src="@/assets/dsee.png"/>
      </h1>
<!--      <h3>Selbstlernen der Kinder werden durch dieses Tool gefördert-->
<!--      </h3>-->
    </div>

  </div>


  <div class="container">
    <div class="grid" style="margin-top: 40px">
      <div class="col-12 md:col-4 lg:col-4">
        <router-link class="text-reset" to="/books">
          <img class="image-footer" src="@/assets/11.jpeg"/>
          <span class="text-footer">Mongolisch Deutsche Lehrbücher</span>
        </router-link>
      </div>
      <div class="col-12 md:col-4 lg:col-4">
        <router-link class="text-reset" to="/lessons">

          <img class="image-footer" src="@/assets/22.jpeg"/>
          <span class="text-footer">Förderfragen und Hilfe</span>

        </router-link>

      </div>
      <div class="col-12 md:col-4 lg:col-4">
        <router-link class="text-reset" to="/tickets">

          <img class="image-footer" src="@/assets/33.jpeg"/>
          <span class="text-footer">Lehrübungen und Spiele</span>
        </router-link>
      </div>
    </div>
    <div class="grid" style="margin-top: 20px">
      <p class="explainText">
        Kinder und Jugendliche sollen nach der Corona-Pandemie schnell wieder Versäumtes aufholen und nachholen können.
        Das gilt nicht nur für den Lernstoff, sondern auch für ihr soziales Leben. Sie sollen Zeit haben für Freunde,
        Sport und Freizeit und die Unterstützung bekommen, die sie und ihre Familien jetzt brauchen. Deshalb hat die
        Bundesregierung das Aktionsprogramm "Aufholen nach Corona für Kinder und Jugendliche" für die Jahre 2021 und
        2022 beschlossen.<br/>

        Die Deutsche Stiftung für Engagement und Ehrenamt hat das Förderprogramm <strong>
        "ZukunftsMUT"</strong> zur Förderung vor allem
        ehrenamtlich getragener Projekte aufgelegt, um junge Menschen und Familien zu unterstützen. Auf
        bürgerschaftliches Engagement und Ehrenamt gestützte Organisationen und Vereine sollen Angebote für Kinder,
        Jugendliche und Familien für mehr soziales Miteinander und Engagement, Bewegung, Gesundheit und Bildung machen
        können. In diesem Rahmen wird das Projekt "Digital trifft Lernspass" durch unsere Organisation <strong>"Verein
        der
        mongolischen Akademiker e.V."</strong> umgesetzt.
      </p>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
        (response) => {
          this.content = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  },
};
</script>
<style>
.homeSection {
  background-image: url('~@/assets/kids.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  min-height: 500px;
}

.hero-text {
  text-align: center;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  background: rgba(180, 214, 112, 0.9);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  color: #00576f;
}

.image-footer {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #707070;
  width: 100%;
}

.text-footer {
  text-transform: uppercase;
  text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
  fill: #464646;
  color: #464646;
  background-color: #fcb65e;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
  width: 100%;
  padding: 10px;
  float: left;
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
}

.explainText {
  color: #2D2D2D;
  font-size: 16px;
  margin-top: 30px;
}

.foerderung {
  width: 320px;
  padding: 10px;
  margin-left: 30px;
}

</style>
